

  [data-testid="Download CSV-iconButton"] {
    display: none !important;
}

[data-testid="Print-iconButton"] {
    display: none !important;
}

